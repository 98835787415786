import React, { Component } from 'react';
import { Tabs } from 'antd';
import MaiginReportGrid from './MaiginReportGrid';
import TreasuryReportGrid from './TreasuryReportGrid';
import CommissionRateReportGrid from './commission/CommissionRateReportGrid';
import SwapTradeParameterPannel from './fin/SwapTradeParameterPannel';
import TreasuryDashbordPannel from './dashbord/TreasuryDashbordPannel';
import StockLoanPannel from './stockloan/StockLoanPannel';
import OnshoreMarginReportGrid from './margin/OnshoreMarginReportGrid';
import config from 'common/config';
import './TreasuryDashboard.css';

const TabPane = Tabs.TabPane;
class TreasuryDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRiskInfo: null
    };
  }

  onRiskInfoChanged = riskInfo => {
    // this.setState({
    //   selectedRiskInfo: riskInfo
    // });
  };

  componentDidMount() {
    const {
      settings: { user }
    } = this.props;
    if (!user) return;
  }

  isOnshore = () => {
    return config.system === 'ONSHORE';
  };

  render() {
    return (
      <div className="treasuryDashboardWrapper">
        <Tabs defaultActiveKey="5" tabPosition={'top'}>
          <TabPane tab="Dashbord" key="5">
            <TreasuryDashbordPannel {...this.props} />
          </TabPane>
          {!this.isOnshore() && (
            <TabPane tab="Margin" key="1">
              <MaiginReportGrid {...this.props} />
            </TabPane>
          )}
          {this.isOnshore() && (
            <TabPane tab="Margin" key="7">
              <OnshoreMarginReportGrid {...this.props} />
            </TabPane>
          )}
          <TabPane tab="Exposure" key="2">
            <TreasuryReportGrid {...this.props} />
          </TabPane>
          <TabPane tab="Commission" key="3">
            <CommissionRateReportGrid {...this.props} />
          </TabPane>
          <TabPane tab="Stock loan" key="4">
            <StockLoanPannel {...this.props} />
          </TabPane>
          <TabPane tab="Finance" key="6">
            <SwapTradeParameterPannel {...this.props} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default TreasuryDashboard;
