import {
  moneyFormatter,
  nullFormatter,
  nanFormatter,
  twoDPFormatter,
  dateFormatter,
  fxRateFormatter,
  threeDPFormatter,
  percentFormatter
} from 'common/utils/valueFormatters';
import {
  sideStyle,
  numberStyle,
  percentStyle
} from 'common/utils/styleChooser';
import {
  sideRenderer,
  mmaExchRenderer,
  objectRenderer,
  orderTypeRenderer,
  booleanRenderer,
  dateRenderer,
  numberRenderer,
  upperCaseRenderer,
  dateTimeRenderer,
  longTextShowRenderer
} from 'common/ui/renderers/hotTable/renderers';
import Options from '../../entities/Options';
import orderClient from 'features/order/api/client';
import moment from 'moment';

const quantOrderGridColumns = [
  {
    field: 'fund',
    headerName: 'Fund',
    cellClass: 'non-number',
    valueFormatter: nullFormatter
  },
  {
    field: 'book',
    headerName: 'Book',
    cellClass: 'non-number',
    valueFormatter: nullFormatter
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword',
    lockPinned: true
  },
  {
    field: 'side',
    headerName: 'Side',
    cellClass: params =>
      ['BUY', 'COVR'].includes(params.value) ? 'long' : 'short'
  },
  {
    field: 'quantity',
    headerName: 'Qty',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClassRules: sideStyle()
  }
];

const commonHoldingGridColumns = [
  {
    field: 'fundCode',
    headerName: 'Fund',
    enableRowGroup: true,
    cellClass: 'non-number',
    enablePivot: true
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    enableRowGroup: true,
    cellClass: 'non-number',
    enablePivot: true
  },
  {
    field: 'strategy',
    headerName: 'Strategy',
    enableRowGroup: true,
    cellClass: 'non-number',
    enablePivot: true
  },
  {
    field: 'custodianCode',
    headerName: 'PB',
    enableRowGroup: true,
    cellClass: 'keyword'
  },
  {
    field: 'custodianAccountCode',
    headerName: 'Account',
    enableRowGroup: true,
    cellClass: 'keyword',
    hide: true
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword'
  },
  {
    field: 'securityCode',
    headerName: 'Security',
    enableRowGroup: true,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'currencyCode',
    headerName: 'CCY',
    enableRowGroup: true,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'instrumentClass',
    headerName: 'Inst Class',
    enableRowGroup: true,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'positionTypeCode',
    headerName: 'Pos Type',
    enableRowGroup: true,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'quantityStart',
    headerName: 'Qty Start',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'quantityStartForClose',
    headerName: 'Qty Start For Close',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'borrowRate',
    headerName: 'Borrow Rate%',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: twoDPFormatter
  },
  {
    field: 'lastUpdatedOn',
    headerName: 'Last Updated',
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'loanQtyStart',
    headerName: 'Loan Qty Start',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'loanQtyEnd',
    headerName: 'Loan Qty End',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'ctQtyStart',
    headerName: 'CT Qty Start',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'ctQtyEnd',
    headerName: 'CT Qty End',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  }
  // {
  //   field: 'quantityEnd',
  //   headerName: 'Qty',
  //   cellRenderer: 'agAnimateShowChangeCellRenderer',
  //   valueFormatter: moneyFormatter,
  //   cellClassRules: numberStyle()
  // },
  // {
  //   field: 'deltaNotnlMktValUsd',
  //   headerName: 'MV',
  //   cellRenderer: 'agAnimateShowChangeCellRenderer',
  //   valueFormatter: moneyFormatter,
  //   aggFunc: 'sum',
  //   enableValue: true,
  //   cellClassRules: numberStyle()
  // },
  // {
  //   field: 'deltaNotnlMktValUsdBookPct',
  //   headerName: 'MV Book %',
  //   cellRenderer: 'agAnimateShowChangeCellRenderer',
  //   valueFormatter: percentFormatter,
  //   cellClassRules: percentStyle()
  // },
  // {
  //   field: 'deltaNotnlMktValUsdFundPct',
  //   headerName: 'MV Fund %',
  //   cellRenderer: 'agAnimateShowChangeCellRenderer',
  //   valueFormatter: percentFormatter,
  //   cellClassRules: percentStyle()
  // }
];

const holdingGridColumns = [
  ...commonHoldingGridColumns,
  {
    field: 'notnlMktValUsd',
    headerName: 'MV USD',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: numberStyle()
  },
  {
    field: 'notnlMktValBookPct',
    headerName: 'MV Book %',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    cellClassRules: percentStyle()
  },
  {
    field: 'notnlMktValFundPct',
    headerName: 'MV Fund %',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    cellClassRules: percentStyle()
  },
  {
    field: 'qtyFactor',
    headerName: 'Qty Factor',
    cellClass: 'non-number'
  },
  {
    field: 'loanType',
    headerName: 'Loan Type',
    enableRowGroup: false,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'quantityEnd',
    headerName: 'Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'availLoanTxns',
    headerName: 'AvailContTxns'
  },
  {
    field: 'theoQtyStartLeft',
    headerName: 'Qty Theo Start Left',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'theoreticalQtyForClose',
    headerName: 'Qty Theo Close',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'theorQty',
    headerName: 'Qty Theo',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'endPriceLocal',
    headerName: 'Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    hide: true
  },
  {
    field: 'usdFx',
    headerName: 'USD Fx',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: fxRateFormatter,
    hide: true
  },
  {
    field: 'bookFx',
    headerName: 'Book Fx',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: fxRateFormatter,
    hide: true
  },
  {
    field: 'cfdType',
    headerName: 'Cfd Type',
    cellClass: 'non-number'
  },
  {
    field: 'accountType',
    headerName: 'Account Type',
    cellClass: 'non-number'
  },
  {
    field: 'quantityDirection',
    headerName: 'Direction',
    cellClass: 'non-number'
  }
];

const executeRouteHoldingGridColumns = [
  ...commonHoldingGridColumns,
  {
    field: 'availLoanTxnsStr',
    headerName: 'AvailContTxns'
  },
  {
    field: 'theoQtyStartLeft',
    headerName: 'Qty Theo Start Left',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'theoreticalQty',
    headerName: 'Qty Theo',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'theoreticalQtyForClose',
    headerName: 'Qty Theo Close',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'cfdType',
    headerName: 'Cfd Type',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'accountType',
    headerName: 'Acct Type',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'qtyFactor',
    headerName: 'Qty Factor',
    cellClass: 'non-number'
  },
  {
    field: 'loanType',
    headerName: 'Loan Type',
    enableRowGroup: false,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  }
];

const orderChangesGridColumns = [
  {
    field: 'updateDate',
    headerName: 'Time',
    enableRowGroup: true,
    rowGroupIndex: 0,
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'field',
    headerName: 'Field',
    cellClass: 'keyword'
  },
  {
    field: 'oldValue',
    headerName: 'Old Val',
    valueFormatter: nanFormatter
  },
  {
    field: 'newValue',
    headerName: 'New Val',
    valueFormatter: nanFormatter
  }
];

const orderGridColumns = [
  {
    data: 'fundCode',
    headerName: 'Fund',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'bookCode',
    headerName: 'Book',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'strategy',
    headerName: 'Strategy',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'tradeDate',
    headerName: 'Trade Date',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'side',
    headerName: 'Side',
    allowEmpty: false,
    readOnly: true,
    renderer: sideRenderer
  },
  {
    data: 'quantity',
    headerName: 'Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'filledQuantity',
    headerName: 'Filled',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'filledPct',
    headerName: 'Filled %',
    type: 'numeric',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'timeInForce',
    headerName: 'TIF',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'traderCode',
    headerName: 'Trdr Name',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'hasErrors',
    headerName: 'Errors',
    renderer: booleanRenderer,
    readOnly: true
  }
];

const routeGridColumns = [
  {
    data: 'fundCode',
    headerName: 'Fund',
    readOnly: false,
    allowEmpty: false
  },
  {
    data: 'bookCode',
    headerName: 'Book',
    readOnly: false,
    allowEmpty: false
  },
  {
    data: 'order.strategy',
    headerName: 'Strategy',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'venue',
    headerName: 'Venue',
    allowEmpty: false,
    type: 'dropdown',
    source: Options.venue
  },
  {
    data: 'orderType',
    headerName: 'Type',
    allowEmpty: false,
    type: 'dropdown',
    source: Options.orderType,
    renderer: orderTypeRenderer
  },
  {
    data: 'limitPrice',
    headerName: 'Limit Px',
    type: 'numeric'
  },
  {
    data: 'order.pmRemark',
    headerName: 'Remark',
    readOnly: true
  },
  {
    data: 'algoCode',
    headerName: 'Algo',
    type: 'dropdown',
    source: Options.algo
  },
  {
    data: 'algoParams',
    headerName: 'Algo Params',
    readOnly: true,
    renderer: objectRenderer
  },
  {
    data: 'isDirectLine',
    headerName: 'DirL',
    type: 'checkbox'
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    allowEmpty: false
  },
  {
    data: 'mmaTicker',
    headerName: 'MMA',
    readOnly: true,
    renderer: mmaExchRenderer
  },
  {
    data: 'side',
    headerName: 'Side',
    allowEmpty: false,
    renderer: sideRenderer,
    type: 'dropdown',
    source: Options.side
  },
  {
    data: 'cfdType',
    headerName: 'CFD',
    type: 'dropdown',
    source: Options.cfdType
  },
  {
    data: 'qty',
    headerName: 'Qty',
    allowEmpty: false,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    }
  },
  {
    data: 'filled',
    headerName: 'Filled',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    }
  },
  {
    data: 'avgPrice',
    headerName: 'Avg Px',
    type: 'numeric'
  },
  {
    data: 'fxRate',
    headerName: 'Fx Rate',
    type: 'numeric'
  },
  {
    data: 'broker',
    headerName: 'Broker',
    allowEmpty: false,
    type: 'dropdown',
    source: []
  },
  {
    data: 'custodian',
    headerName: 'Custodian',
    allowEmpty: false,
    type: 'dropdown',
    source: []
  },
  {
    data: 'handlingInstruction',
    headerName: 'HandInst',
    type: 'dropdown',
    source: Options.handlingInstruction
  },
  {
    data: 'settlementCcy',
    headerName: 'CCY'
  },
  {
    data: 'borrowRate',
    headerName: 'Rate %',
    type: 'numeric'
  },
  {
    data: 'loanType',
    headerName: 'PTH/C',
    type: 'dropdown',
    source: Options.loanType
  },
  {
    data: 'notes',
    headerName: 'Reason',
    allowEmpty: true,
    type: 'dropdown',
    source: []
  },
  {
    data: 'lockDate',
    headerName: 'Lock DT',
    allowEmpty: true,
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    correctFormat: true
  },
  {
    data: 'hasErrors',
    headerName: 'E',
    renderer: booleanRenderer,
    readOnly: true
  },
  {
    data: 'hasWarnings',
    headerName: 'W',
    renderer: booleanRenderer,
    readOnly: true
  },
  {
    data: 'subSource',
    headerName: 'Sub Source',
    readOnly: true
  },
  {
    data: 'contractId',
    headerName: 'Notes1',
    readOnly: true
  }
];

const routeIPOGridColumns = [
  {
    data: 'fundCode',
    headerName: 'Fund',
    readOnly: false,
    allowEmpty: false
  },
  {
    data: 'bookCode',
    headerName: 'Book',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'order.strategy',
    headerName: 'Strategy',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'venue',
    headerName: 'Venue',
    allowEmpty: false,
    type: 'dropdown',
    source: Options.venue
  },
  {
    data: 'orderType',
    headerName: 'Type',
    allowEmpty: false,
    type: 'dropdown',
    source: Options.orderType,
    renderer: orderTypeRenderer
  },
  {
    data: 'limitPrice',
    headerName: 'Limit Px',
    type: 'numeric'
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    allowEmpty: false
  },
  {
    data: 'side',
    headerName: 'Side',
    allowEmpty: false,
    renderer: sideRenderer,
    type: 'dropdown',
    source: Options.side
  },
  {
    data: 'cfdType',
    headerName: 'CFD',
    type: 'dropdown',
    source: Options.cfdType
  },
  {
    data: 'order.qtyUsd',
    headerName: 'Order Value',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0 $'
    },
    readOnly: true
  },
  {
    data: 'order.qtyPct',
    headerName: 'AUM %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    readOnly: true
  },
  {
    data: 'qty',
    headerName: 'Qty',
    allowEmpty: false,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    }
  },
  {
    data: 'filled',
    headerName: 'Filled',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    }
  },
  {
    data: 'filledValue',
    headerName: 'Filled Value',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    readOnly: true
  },
  {
    data: 'filledPer',
    headerName: 'Filled%',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000 %'
    },
    readOnly: true
  },
  {
    data: 'avgPrice',
    headerName: 'Avg Px',
    type: 'numeric'
  },
  {
    data: 'broker',
    headerName: 'Broker',
    allowEmpty: false,
    type: 'dropdown',
    source: []
  },
  {
    data: 'custodian',
    headerName: 'Custodian',
    allowEmpty: false,
    type: 'dropdown',
    source: []
  },
  {
    data: 'handlingInstruction',
    headerName: 'HandInst',
    type: 'dropdown',
    source: Options.handlingInstruction
  },
  {
    data: 'settlementCcy',
    headerName: 'CCY'
  },
  {
    data: 'notes',
    headerName: 'Reason',
    allowEmpty: true,
    type: 'dropdown',
    source: []
  },
  {
    data: 'hasErrors',
    headerName: 'E',
    renderer: booleanRenderer,
    readOnly: true
  },
  {
    data: 'hasWarnings',
    headerName: 'W',
    renderer: booleanRenderer,
    readOnly: true
  }
];

const ruleGridColumns = [
  {
    field: 'refId',
    headerName: 'Ref Id',
    enableRowGroup: true,
    cellClass: 'keyword'
  },
  {
    field: 'type',
    headerName: 'Type',
    enableRowGroup: true,
    cellClass: 'comment'
  },
  {
    field: 'subType',
    headerName: 'Sub Type',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        DEFAULT: 'coral',
        EB: 'steelblue',
        PB: '#87d068',
        AXE: 'chocolate'
      }
    }
  },
  {
    field: 'trader',
    headerName: 'Trader',
    enableRowGroup: true,
    cellClass: 'comment'
  },
  {
    field: 'code',
    headerName: 'Name',
    enableRowGroup: true,
    cellClass: 'keyword'
  },
  {
    field: 'criteria',
    headerName: 'Criteria',
    cellClass: 'non-number',
    cellRenderer: 'jsonTooltipComponent'
  },
  {
    field: 'target',
    headerName: 'Target',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    cellRenderer: 'jsonTooltipComponent'
  },
  {
    field: 'timeRange',
    headerName: 'Time Range',
    cellClass: 'comment'
  },
  {
    field: 'priority',
    headerName: 'Priority',
    cellClass: 'keyword'
  },
  {
    field: 'isActive',
    headerName: 'Active',
    cellClass: 'non-number',
    cellRenderer: 'switchComponent'
  }
];

const locateGridColumns = [
  {
    data: 'fundCode',
    headerName: 'Fund',
    readOnly: false,
    allowEmpty: false
  },
  {
    data: 'bookCode',
    headerName: 'Book',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'order.strategy',
    headerName: 'Strategy',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'order.pmRemark',
    headerName: 'Remark',
    readOnly: true
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    allowEmpty: false
  },
  {
    data: 'mmaTicker',
    headerName: 'MMA',
    readOnly: true,
    renderer: mmaExchRenderer
  },
  // {
  //   data: 'order.side',
  //   headerName: 'Side',
  //   allowEmpty: false,
  //   renderer: sideRenderer,
  //   type: 'dropdown',
  //   source: Options.side,
  //   readOnly: true
  // },
  {
    data: 'qty',
    headerName: 'Qty',
    allowEmpty: false,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    }
  },
  {
    data: 'broker',
    headerName: 'Broker',
    allowEmpty: false,
    type: 'dropdown',
    source: []
  },
  {
    data: 'hasErrors',
    headerName: 'E',
    renderer: booleanRenderer,
    readOnly: true
  },
  {
    data: 'hasWarnings',
    headerName: 'W',
    renderer: booleanRenderer,
    readOnly: true
  },
  {
    data: 'msg',
    headerName: 'Source',
    readOnly: true
  }
];

const shortListGridColumns = [
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword',
    width: 100
  },
  {
    field: 'broker',
    headerName: 'Broker',
    cellClass: 'keyword',
    enableRowGroup: true
  },
  {
    field: 'rate',
    headerName: 'Rate %',
    cellClass: 'number',
    valueFormatter: twoDPFormatter
  },
  {
    field: 'availableQty',
    headerName: 'AvailableQty',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  }
];

const locateHoldingGridColumns = [
  {
    field: 'broker',
    headerName: 'Broker',
    cellClass: 'keyword',
    enableRowGroup: true,
    rowGroupIndex: 0,
    width: 60
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword',
    width: 100
  },
  {
    field: 'qty',
    headerName: 'Qty',
    valueFormatter: moneyFormatter,
    cellClass: 'number',
    width: 70,
    aggFunc: 'sum'
  },
  {
    field: 'usedQty',
    headerName: 'Used Qty',
    valueFormatter: moneyFormatter,
    cellClass: 'number',
    width: 70,
    aggFunc: 'sum'
  },
  {
    field: 'leftQty',
    headerName: 'Left Qty',
    valueFormatter: moneyFormatter,
    cellClass: 'number',
    width: 70,
    aggFunc: 'sum'
  },
  {
    field: 'borrowRate',
    headerName: 'Rate',
    cellClass: 'keyword',
    valueFormatter: nullFormatter,
    width: 80
  }
];

const overrideCPLGridColumns = [
  {
    data: 'fundCode',
    headerName: 'Fund',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'bookCode',
    headerName: 'Book',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'strategy',
    headerName: 'Strategy',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'tradeDate',
    headerName: 'Trade Date',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'side',
    headerName: 'Side',
    allowEmpty: false,
    readOnly: true,
    renderer: sideRenderer
  },
  {
    data: 'quantity',
    headerName: 'Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'complianceStatus',
    headerName: 'Compliance Status',
    allowEmpty: false,
    editor: 'select',
    selectOptions: ['PASS', 'FAIL', 'SKIP', 'WARN', 'PENDING']
  },
  {
    data: 'complianceError',
    headerName: 'Compliance Error',
    type: 'text'
  }
];

const enrichOrdersGridColumns = [
  {
    data: 'fundCode',
    headerName: 'Fund',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'bookCode',
    headerName: 'Book',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'strategy',
    headerName: 'Strategy',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'side',
    headerName: 'Side',
    allowEmpty: false,
    readOnly: true,
    renderer: sideRenderer
  },
  {
    data: 'quantity',
    headerName: 'Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'tradeDate',
    headerName: 'Trade Date',
    allowEmpty: false,
    type: 'date',
    dateFormat: 'YYYY-MM-DD'
  },
  {
    data: 'traderNote',
    headerName: 'Trader Note',
    type: 'autocomplete',
    strict: false,
    source: [
      '[CH_OFFSET]: recall order',
      '[CH_OFFSET]: quant order',
      '[CH_OFFSET]: offset with quant orders',
      '[CH_OFFSET]: offset with recall orders'
    ],
    allowEmpty: true
  },
  {
    data: 'symbol',
    headerName: 'Symbol',
    type: 'text'
  },
  {
    data: 'mmaTicker',
    headerName: 'MMA',
    type: 'text'
  }
];

const ipoListGridColumns = [
  {
    data: 'id',
    headerName: 'Id',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'announcedDate',
    headerName: '<span class="requiredIPOHeader">Announced Date</span>',
    allowEmpty: false,
    renderer: dateRenderer,
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    correctFormat: true,
    datePickerConfig: {
      firstDay: 0,
      showWeekNumber: true,
      numberOfMonths: 1,
      disableDayFn: function(date) {
        return date.getDay() === 0 || date.getDay() === 6;
      }
    }
  },
  {
    data: 'status',
    headerName: '<span class="requiredIPOHeader">Status</span>',
    editor: 'select',
    selectOptions: ['PENDING', 'APPROVE', 'REJECT'],
    width: 100
  },
  {
    data: 'issuerTicker',
    headerName: '<span class="requiredIPOHeader">Ticker</span>',
    readOnly: false,
    allowEmpty: false
  },
  {
    data: 'issuerName',
    headerName: '<span class="requiredIPOHeader">Security Name</span>',
    readOnly: false
  },
  {
    data: 'industryGroup',
    headerName: '<span class="requiredIPOHeader">Industry Group</span>',
    allowEmpty: false
  },
  {
    data: 'industrySector',
    headerName: '<span class="requiredIPOHeader">Industry Sector</span>',
    allowEmpty: false
  },
  {
    data: 'listingDate',
    headerName: '<span class="requiredIPOHeader">Listing Date</span>',
    renderer: dateRenderer,
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    correctFormat: true,
    datePickerConfig: {
      firstDay: 0,
      showWeekNumber: true,
      numberOfMonths: 1,
      disableDayFn: function(date) {
        return date.getDay() === 0 || date.getDay() === 6;
      }
    }
  },
  {
    data: 'bookbuildingStartDate',
    headerName: 'Bookbuilding Start Date',
    renderer: dateRenderer,
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    correctFormat: true,
    datePickerConfig: {
      firstDay: 0,
      showWeekNumber: true,
      numberOfMonths: 1,
      disableDayFn: function(date) {
        return date.getDay() === 0 || date.getDay() === 6;
      }
    }
  },
  {
    data: 'bookbuildingEndDate',
    headerName: '<span class="requiredIPOHeader">Bookbuilding End Date</span>',
    renderer: dateRenderer,
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    correctFormat: true,
    datePickerConfig: {
      firstDay: 0,
      showWeekNumber: true,
      numberOfMonths: 1,
      disableDayFn: function(date) {
        return date.getDay() === 0 || date.getDay() === 6;
      }
    }
  },
  {
    data: 'bookrunner',
    headerName: 'Bookrunner',
    width: 300
  },
  {
    data: 'offerSize',
    headerName: 'Offer Size',
    renderer: numberRenderer
  },
  {
    data: 'marketCapAtOffer',
    headerName: 'Market Cap At Offer',
    renderer: numberRenderer
  },
  {
    data: 'offerType',
    headerName: 'Offer Type'
  },
  {
    data: 'offerStage',
    headerName: 'Offer Stage'
  },
  {
    data: 'filingTermPriceRange',
    headerName: 'Filing Term Price Range'
  },
  {
    data: 'offerPrice',
    headerName: 'Offer Price'
  },

  {
    data: 'expectedPricingDate',
    headerName: 'Expected Pricing Date',
    renderer: dateRenderer,
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    correctFormat: true,
    datePickerConfig: {
      firstDay: 0,
      showWeekNumber: true,
      numberOfMonths: 1,
      disableDayFn: function(date) {
        return date.getDay() === 0 || date.getDay() === 6;
      }
    }
  },
  {
    data: 'primaryExchange',
    headerName: 'Primary Exchange'
  }
];

const manualLocateGridColumns = [
  {
    data: 'orderRefId',
    headerName: 'Order RefId',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'fundCode',
    headerName: 'Fund',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'bookCode',
    headerName: 'Book',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    allowEmpty: false,
    readOnly: true
  },
  {
    data: 'broker',
    headerName: 'Broker',
    allowEmpty: false,
    readOnly: true
  },
  // {
  //   data: 'order.side',
  //   headerName: 'Side',
  //   allowEmpty: false,
  //   renderer: sideRenderer,
  //   type: 'dropdown',
  //   source: Options.side,
  //   readOnly: true
  // },
  {
    data: 'qty',
    headerName: 'Qty',
    allowEmpty: false,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    readOnly: true
  },
  {
    data: 'allocateQty',
    headerName: 'Allocate Qty',
    allowEmpty: false,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    readOnly: true
  },
  {
    data: 'diff',
    headerName: 'Diff',
    allowEmpty: false,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    readOnly: true
  }
];

const manualLocateTickerGridColumns = [
  {
    data: 'ticker',
    headerName: 'Ticker',
    allowEmpty: false,
    readOnly: true
  },
  // {
  //   data: 'qty',
  //   headerName: 'Total Qty',
  //   allowEmpty: false,
  //   type: 'numeric',
  //   readOnly: true,
  //   numericFormat: {
  //     pattern: '0,0'
  //   }
  // },
  {
    data: 'broker',
    headerName: 'Broker',
    allowEmpty: false,
    type: 'dropdown'
  },
  {
    data: 'qty',
    headerName: 'Qty',
    allowEmpty: false,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    }
  },
  {
    data: 'allocateQty',
    headerName: 'Filled',
    allowEmpty: true,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    readOnly: false
  },
  {
    data: 'diff',
    headerName: 'Diff',
    readOnly: true,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    }
  },
  {
    data: 'borrowRate',
    headerName: 'Borrow Rate %',
    readOnly: false,
    type: 'numeric',
    numericFormat: {
      pattern: '0.00'
    }
  },
  {
    data: 'fund',
    headerName: 'Fund',
    readOnly: false,
    allowEmpty: false
  }
];

const manualAllocateLocateGridColumns = [
  {
    data: 'orderRefId',
    headerName: 'Order RefId',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'fundCode',
    headerName: 'Fund',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'bookCode',
    headerName: 'Book',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    allowEmpty: false,
    readOnly: true
  },
  {
    data: 'broker',
    headerName: 'Broker',
    allowEmpty: false,
    readOnly: true
  },
  // {
  //   data: 'order.side',
  //   headerName: 'Side',
  //   allowEmpty: false,
  //   renderer: sideRenderer,
  //   type: 'dropdown',
  //   source: Options.side,
  //   readOnly: true
  // },
  {
    data: 'allocateQty',
    headerName: 'Qty',
    allowEmpty: false,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    readOnly: false
  },
  {
    data: 'borrowRate',
    headerName: 'Borrow Rate %',
    readOnly: true,
    type: 'numeric',
    numericFormat: {
      pattern: '0.00'
    }
  }
];

const cancelRoutesGridColumns = [
  {
    data: 'refId',
    headerName: 'Ref Id',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'fundCode',
    headerName: 'Fund',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'bookCode',
    headerName: 'Book',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'tradeDate',
    headerName: 'Trade Date',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'broker',
    headerName: 'Broker',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'side',
    headerName: 'Side',
    allowEmpty: false,
    readOnly: true,
    renderer: sideRenderer
  },
  {
    data: 'qty',
    headerName: 'Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'externalId',
    headerName: 'Ext Id',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'isDirectLine',
    headerName: 'DirL',
    type: 'checkbox',
    readOnly: true
  }
];

const nonOrderLocatesGridColumns = [
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword',
    width: 100
  },
  {
    field: 'broker',
    headerName: 'Broker',
    cellClass: 'text',
    enableRowGroup: true
  },
  {
    field: 'qty',
    headerName: 'Qty',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClass: 'text',
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        OMS_SENT: 'lightseagreen',
        FILLED: '#FF9E28',
        PARTFILL: 'steelblue',
        REJECTED: 'crimson'
      }
    }
  },
  {
    field: 'tradeDate',
    headerName: 'Trade Date',
    valueFormatter: dateFormatter,
    enableRowGroup: true
  },
  {
    field: 'borrowRate',
    headerName: 'Rate %',
    cellClass: 'number',
    valueFormatter: twoDPFormatter
  },
  {
    field: 'filled',
    headerName: 'Filled',
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'locateId',
    headerName: 'Locate Id',
    cellClass: 'text'
  },
  {
    field: 'createdOn',
    headerName: 'Created On',
    cellClass: 'text'
  }
];

const nonOrderLocatesTableColumns = [
  {
    data: 'ticker',
    headerName: 'Ticker',
    type: 'autocomplete',
    source(query, process) {
      if (query.length <= 3) return [];
      orderClient
        .searchTickers(query)
        .then(response => process(response.tickers));
    },
    width: 100
  },
  {
    data: 'qty',
    headerName: 'Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false,
    width: 60
  },
  {
    data: 'broker',
    headerName: 'Broker',
    type: 'dropdown',
    source: [],
    allowEmpty: false,
    width: 80
  },
  {
    data: 'fundCode',
    headerName: 'Fund',
    type: 'dropdown',
    source: ['DCL', 'CVF'],
    allowEmpty: false,
    width: 80
  },
  {
    data: 'notes',
    headerName: 'Notes',
    allowEmpty: false,
    width: 60
  },
  {
    data: 'rate',
    headerName: 'Borrow Rate %',
    readOnly: true,
    type: 'numeric',
    numericFormat: {
      pattern: '0.00'
    },
    width: 50
  },
  {
    data: 'availableQty',
    headerName: 'Available Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    readOnly: true,
    width: 50
  },
  {
    data: 'offShore',
    headerName: 'Off Shore',
    readOnly: true
  }
];

const contractRolloverTableColumns = [
  {
    data: 'fund',
    headerName: 'Fund',
    type: 'text',
    readOnly: true,
    width: 40
  },
  {
    data: 'book',
    headerName: 'Book',
    readOnly: true,
    type: 'text',
    width: 40
  },
  {
    data: 'custodian',
    headerName: 'Custodian',
    readOnly: true,
    type: 'text',
    width: 50
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    readOnly: true,
    type: 'text',
    width: 100
  },
  {
    data: 'qty',
    headerName: 'Qty',
    type: 'numeric',
    readOnly: true,
    numericFormat: {
      pattern: '0,0'
    },
    width: 60
  },
  {
    data: 'pthQty',
    headerName: 'PTH Qty',
    readOnly: true,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false,
    width: 60
  },
  {
    data: 'contractId',
    headerName: 'Contract Id',
    type: 'text',
    allowEmpty: false,
    width: 140
  },
  {
    data: 'newCtQty',
    headerName: 'Ct Qty(N)',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    width: 50
  },
  {
    data: 'ctTradeDate',
    headerName: 'CT Date(N)',
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    correctFormat: true,
    width: 70,
    datePickerConfig: {
      firstDay: 0,
      showWeekNumber: true,
      numberOfMonths: 1
    }
  },
  {
    data: 'newMaturityDate',
    headerName: 'Maturity',
    type: 'date',
    dateFormat: 'YYYYMMDD',
    correctFormat: true,
    width: 70,
    datePickerConfig: {
      firstDay: 0,
      showWeekNumber: true,
      numberOfMonths: 1
    }
  },
  {
    data: 'borrowRate',
    headerName: 'Rate',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    width: 30
  },
  {
    data: 'closePTHQty',
    headerName: 'PTH Qty(C)',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    width: 50
  },
  {
    data: 'closePTHDate',
    headerName: 'PTH Date(C)',
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    correctFormat: true,
    width: 70,
    datePickerConfig: {
      firstDay: 0,
      showWeekNumber: true,
      numberOfMonths: 1,
      disableDayFn: function(date) {
        return date < moment().subtract(1, 'days');
      }
    }
  },
  {
    data: 'remark',
    headerName: 'Remark',
    readOnly: false,
    type: 'text',
    width: 140
  }
];

const txnLimitsTableColumns = [
  {
    data: 'ticker',
    headerName: 'Ticker',
    type: 'text',
    width: 100
  },
  {
    data: 'symbol',
    headerName: 'Symbol',
    type: 'text',
    width: 100
  },
  {
    data: 'type',
    headerName: 'Type',
    type: 'text',
    width: 100,
    renderer: upperCaseRenderer
  },
  {
    data: 'value',
    headerName: 'Value',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    width: 100
  },
  {
    data: 'effectiveFrom',
    headerName: 'Effective From',
    type: 'date',
    readOnly: true,
    renderer: dateTimeRenderer,
    width: 100
  }
];

const mktSuspensionColumns = [
  {
    data: 'tradeDate',
    headerName: 'Date',
    type: 'text',
    width: 100
  },
  {
    data: 'market',
    headerName: 'Market',
    editor: 'select',
    selectOptions: ['SSE_SEHK_CONN', 'SZSE_SEHK_CONN'],
    allowEmpty: false,
    width: 100
  }
];

const brokersInfoTableColumns = [
  {
    field: 'type',
    headerName: 'Type',
    cellClass: 'keyword',
    width: 100
  },
  {
    field: 'date',
    headerName: 'Date',
    cellClass: 'non-number',
    width: 100
  },
  {
    field: 'createdOn',
    headerName: 'Create Time',
    cellClass: 'non-number',
    width: 100
  }
];

const brokerCommisionRuleTableColumns = [
  {
    data: 'tier',
    headerName: 'Tier',
    type: 'text',
    width: 40
  },
  {
    data: 'percent',
    headerName: '',
    type: 'numeric',
    width: 100
  }
];

const brokerCommisionResultTableColumns = [
  {
    data: 'tier',
    headerName: 'Tier',
    type: 'text',
    width: 50
  },
  {
    data: 'broker',
    headerName: 'Broker',
    type: 'text',
    width: 80,
    renderer: longTextShowRenderer
  },
  {
    data: 'counterpartyBrokerList',
    headerName: 'EMSX Broker',
    type: 'dropdown',
    width: 150,
    renderer: longTextShowRenderer
  },
  {
    data: 'cfdType',
    headerName: 'CFD',
    type: 'text',
    width: 80
  },
  {
    data: 'custodian',
    headerName: 'Custodian',
    type: 'text',
    width: 80
  },
  {
    data: 'percent',
    headerName: '',
    type: 'numeric',
    width: 50
  }
];

const expensiveBorrowTableColumns = [
  {
    field: 'tradeDate',
    headerName: 'Date',
    cellClass: 'non-number',
    width: 100,
    checkboxSelection: true
  },
  {
    field: 'fund',
    headerName: 'Fund',
    cellClass: 'non-number',
    width: 60
  },
  {
    field: 'team',
    headerName: 'Team',
    cellClass: 'non-number',
    width: 50,
    enableRowGroup: true
  },
  {
    field: 'custodian',
    headerName: 'Custodian',
    cellClass: 'non-number',
    width: 50,
    enableRowGroup: true
  },
  {
    field: 'custodianAccountCode',
    headerName: 'CustodianAccount',
    cellClass: 'non-number',
    width: 100
  },
  {
    field: 'bb_TCM',
    headerName: 'BB_TCM',
    cellClass: 'non-number',
    width: 100,
    enableRowGroup: true
  },
  {
    field: 'feeRate',
    headerName: 'BorrowRate',
    cellClass: 'non-number',
    width: 60
  },
  {
    field: 'qtyShare',
    headerName: 'Quantity',
    cellClass: 'non-number',
    width: 60,
    aggFunc: 'sum',
    valueFormatter: moneyFormatter
  },
  {
    field: 'grossConsiderationSettle',
    headerName: 'Notional(USD)',
    cellClass: 'non-number',
    width: 100,
    aggFunc: 'sum',
    valueFormatter: moneyFormatter
  },
  // {
  //   field: 'brokerRateDiff',
  //   headerName: 'RateDiff',
  //   cellClass: 'non-number',
  //   width: 100
  // },
  // {
  //   field: 'totalTradeFeePct',
  //   headerName: 'TradeFee(bps)',
  //   cellClass: 'non-number',
  //   width: 100
  // },
  // {
  //   field: 'daysToCover',
  //   headerName: 'DaysToCover',
  //   cellClass: 'non-number',
  //   width: 100
  // },
  {
    field: 'brokerRate1',
    headerName: 'BrokerRate1',
    cellClass: 'non-number',
    width: 260,
    cellStyle: params => {
      const style = { fontWeight: 'bold' };
      return {
        ...style,
        backgroundColor:
          params != null &&
          params.data != null &&
          params.data.brokerRate1 != null &&
          params.data.realBrokerRate1 === 1
            ? 'royalblue'
            : ''
      };
    },
    editable: true
  },
  {
    field: 'brokerRate2',
    headerName: 'BrokerRate2',
    cellClass: 'non-number',
    width: 260,
    cellStyle: params => {
      const style = { fontWeight: 'bold' };
      return {
        ...style,
        backgroundColor:
          params != null &&
          params.data != null &&
          params.data.brokerRate2 != null &&
          params.data.realBrokerRate2 === 1
            ? 'royalblue'
            : ''
      };
    }
  },
  {
    field: 'brokerRate3',
    headerName: 'BrokerRate3',
    cellClass: 'non-number',
    width: 260,
    cellStyle: params => {
      const style = { fontWeight: 'bold' };
      return {
        ...style,
        backgroundColor:
          params != null &&
          params.data != null &&
          params.data.brokerRate3 != null &&
          params.data.realBrokerRate3 === 1
            ? 'royalblue'
            : ''
      };
    }
  },
  {
    field: 'otpQty',
    headerName: 'OtpQuantity',
    cellClass: 'non-number',
    width: 100,
    valueGetter: params => {
      if (!params.node.group) {
        return {
          bb_TCM: params.data.bb_TCM,
          custodian: params.data.custodian,
          otpQty: params.data.otpQty,
          toString: () => params.data.otpQty
        };
      }
    },
    valueSettr: params => params.data.otpQty,
    aggFunc: params => {
      const custodianTickerQtySet = new Set();
      let sum = 0;
      if (params.length > 0) {
        params.forEach((value, index) => {
          const custodianTicker = value.bb_TCM + '_' + value.custodian;
          if (!custodianTickerQtySet.has(custodianTicker)) {
            sum += value.otpQty;
            custodianTickerQtySet.add(custodianTicker);
          }
        });
      }
      return sum;
    },
    valueFormatter: moneyFormatter
  },
  {
    field: 'otpSpread',
    headerName: 'OtpSpread',
    width: 60,
    aggFunc: 'avg',
    valueFormatter: twoDPFormatter
  }
];

const cashLocatesTableColumns = [
  {
    data: 'ticker',
    headerName: 'Ticker',
    type: 'autocomplete',
    source(query, process) {
      if (query.length <= 3) return [];
      orderClient
        .searchTickers(query)
        .then(response => process(response.tickers));
    },
    width: 100
  },
  {
    data: 'qty',
    headerName: 'Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false,
    width: 60
  },
  {
    data: 'broker',
    headerName: 'Broker',
    type: 'dropdown',
    source: ['CITIC'],
    allowEmpty: false,
    width: 80
  },
  {
    data: 'fundCode',
    headerName: 'Fund',
    type: 'dropdown',
    source: ['DCL', 'CVF'],
    allowEmpty: false,
    width: 80
  },
  {
    data: 'term',
    headerName: 'Term',
    readOnly: false,
    type: 'numeric',
    width: 50
  },
  {
    data: 'feeRate',
    headerName: 'Fee Rate',
    readOnly: false,
    type: 'numeric',
    width: 50
  },
  {
    data: 'preRate',
    headerName: 'Pre Rate',
    readOnly: false,
    type: 'numeric',
    width: 50
  },
  // {
  //   data: 'postRate',
  //   headerName: 'Post Rate',
  //   readOnly: false,
  //   type: 'numeric',
  //   width: 50
  // },
  // {
  //   data: 'disRate',
  //   headerName: 'Dis Rate',
  //   readOnly: false,
  //   type: 'numeric',
  //   width: 50
  // },
  // {
  //   data: 'availableQty',
  //   headerName: 'Available Qty',
  //   type: 'numeric',
  //   numericFormat: {
  //     pattern: '0,0'
  //   },
  //   readOnly: true,
  //   width: 50
  // },
  // {
  //   data: 'termText',
  //   headerName: 'Term Range',
  //   readOnly: true
  // },
  {
    data: 'hasErrors',
    headerName: 'E',
    renderer: booleanRenderer,
    readOnly: true
  },
  {
    data: 'hasWarnings',
    headerName: 'W',
    renderer: booleanRenderer,
    readOnly: true
  },
  {
    data: 'msg',
    headerName: 'Source',
    readOnly: true
  }
];

const cashLocateGridColumns = [
  {
    field: 'fundCode',
    headerName: 'Fund',
    cellClass: 'keyword',
    enableRowGroup: true,
    rowGroupIndex: 0,
    width: 60
  },
  {
    field: 'broker',
    headerName: 'Broker',
    cellClass: 'keyword',
    enableRowGroup: true,
    rowGroupIndex: 1,
    width: 60
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    cellClass: 'keyword',
    enableRowGroup: true,
    width: 120
  },
  {
    field: 'availableQty',
    headerName: 'Available Qty',
    valueFormatter: moneyFormatter,
    cellClass: 'number',
    width: 70,
    aggFunc: 'sum'
  },
  {
    field: 'termText',
    headerName: 'Term Range',
    enableRowGroup: true,
    cellClass: 'keyword',
    width: 120
  }
];

const cashLocateQuotaColumns = [
  {
    field: 'serialNo',
    headerName: 'SerialNo',
    cellClass: 'number',
    width: 50
  },
  {
    field: 'inventory',
    headerName: 'Inventory',
    cellClass: 'non-number',
    width: 50
  },
  {
    field: 'termText',
    headerName: 'Term Text',
    cellClass: 'non-number',
    width: 100
  },
  {
    field: 'qty',
    headerName: 'Qty',
    cellClass: 'number',
    width: 100
  },
  {
    field: 'corpCode',
    headerName: 'Corp',
    cellClass: 'non-number',
    width: 50
  },
  {
    field: 'busiCode',
    headerName: 'Busi',
    cellClass: 'non-number',
    width: 50
  },
  {
    field: 'chgDate',
    headerName: 'ChgDate',
    cellClass: 'non-number',
    width: 50
  }
];

const recallTableColumns = [
  {
    data: 'fundCode',
    headerName: 'Fund',
    type: 'text',
    allowEmpty: false,
    width: 80
  },
  {
    data: 'custodian',
    headerName: 'Custodian',
    type: 'dropdown',
    allowEmpty: false,
    width: 80
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    type: 'autocomplete',
    source(query, process) {
      if (query.length <= 3) return [];
      orderClient
        .searchTickers(query)
        .then(response => process(response.tickers));
    },
    width: 120
  },
  {
    data: 'cfdType',
    headerName: 'CFD',
    type: 'dropdown',
    source: Options.cfdType
  },
  {
    data: 'qty',
    headerName: 'Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false,
    width: 60
  },
  {
    data: 'reshortQty',
    headerName: 'Reshort Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false,
    width: 60
  },
  {
    data: 'lotSize',
    headerName: 'Lot Size',
    type: 'numeric',
    width: 50,
    readOnly: true
  },
  {
    data: 'leftCovrQty',
    headerName: 'Left Qty(C)',
    type: 'numeric',
    width: 50,
    readOnly: true
  },
  {
    data: 'leftShrtQty',
    headerName: 'Left Qty(S)',
    type: 'numeric',
    width: 50,
    readOnly: true
  }
];

const recallTradesTableColumns = [
  {
    data: 'fundCode',
    headerName: 'Fund',
    type: 'dropdown',
    allowEmpty: false,
    width: 80,
    readOnly: true
  },
  {
    data: 'bookCode',
    headerName: 'Book',
    allowEmpty: false,
    width: 60,
    readOnly: true
  },
  {
    data: 'custodian',
    headerName: 'Custodian',
    allowEmpty: false,
    width: 80,
    readOnly: true
  },
  // {
  //   data: 'strategy',
  //   headerName: 'Strategy',
  //   readOnly: false,
  //   allowEmpty: false
  // },
  {
    data: 'ticker',
    headerName: 'Ticker',
    width: 120,
    readOnly: true
  },
  {
    data: 'cfdType',
    headerName: 'CFD',
    readOnly: false
  },
  {
    data: 'qty',
    headerName: 'Covr Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false,
    width: 60
  },
  {
    data: 'reShortQty',
    headerName: 'Reshort Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false,
    width: 60
  }
];

const recallTargetRatioColumns = [
  {
    field: 'sourceBookCode',
    headerName: 'Source Book',
    cellClass: 'non-number',
    width: 100
  },
  {
    field: 'targetBookCode',
    headerName: 'Target Book',
    cellClass: 'non-number',
    width: 100
  },
  {
    field: 'ratio',
    headerName: 'Ratio',
    cellClass: 'number',
    width: 80
  }
];

const fixLogReviewColumns = [
  {
    field: 'routeRefId',
    headerName: 'Route Id',
    cellClass: 'keyword',
    width: 70,
    enableRowGroup: true,
    rowGroup: true,
    hide: true
  },
  {
    field: 'fundCode',
    headerName: 'Fund',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter,
    width: 40
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter,
    width: 40
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword',
    lockPinned: true,
    width: 80
  },
  {
    field: 'side',
    headerName: 'Side',
    cellClass: params =>
      ['BUY', 'COVR', 'COVR_T'].includes(params.value) ? 'long' : 'short',
    width: 30
  },
  {
    field: 'qty',
    headerName: 'Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle(),
    width: 50
  },
  {
    field: 'filled',
    headerName: 'Filled',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle(),
    width: 50
  },
  {
    field: 'broker',
    headerName: 'Broker',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter,
    width: 30
  },
  {
    field: 'avgPrice',
    headerName: 'Avg Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    width: 50
  },
  {
    field: 'sendingTime',
    headerName: 'Sending Time',
    cellClass: 'non-number',
    width: 80
  }
];

export {
  quantOrderGridColumns,
  holdingGridColumns,
  executeRouteHoldingGridColumns,
  orderChangesGridColumns,
  orderGridColumns,
  routeGridColumns,
  ruleGridColumns,
  locateGridColumns,
  shortListGridColumns,
  locateHoldingGridColumns,
  ipoListGridColumns,
  overrideCPLGridColumns,
  enrichOrdersGridColumns,
  routeIPOGridColumns,
  manualLocateGridColumns,
  manualLocateTickerGridColumns,
  manualAllocateLocateGridColumns,
  cancelRoutesGridColumns,
  nonOrderLocatesGridColumns,
  nonOrderLocatesTableColumns,
  contractRolloverTableColumns,
  txnLimitsTableColumns,
  brokersInfoTableColumns,
  brokerCommisionRuleTableColumns,
  brokerCommisionResultTableColumns,
  expensiveBorrowTableColumns,
  mktSuspensionColumns,
  cashLocatesTableColumns,
  cashLocateGridColumns,
  cashLocateQuotaColumns,
  recallTableColumns,
  recallTradesTableColumns,
  recallTargetRatioColumns,
  fixLogReviewColumns
};
