import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';
import { AgGridReact } from 'ag-grid-react';
import client from '../../api/client';
import _ from 'lodash';
import PercentBarComponent from 'common/ui/renderers/agGrid/PercentBarComponent';
import {
  relPositionGridColumns
} from '../GridColumnMap'

export default class ShowRelPostionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      data: [],
      defaultColDef: {
        enableCellChangeFlash: true,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      columnDefs: relPositionGridColumns,
      frameworkComponents: {
        percentBarComponent: PercentBarComponent,
      },
    };
  }

  componentDidMount() {
    this._loadData();
  }

  _loadData = () => {
    this.setState({
      isLoaded: true
    })
    client
      .getRelPosition()
      .then(resp => {
        this.setState({
          data: resp.data,
          isLoaded: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isLoaded: false
        });
      });
  };

  _onGridReady = params => {
    this.gridApi = params.api;
    this.golumnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  _createGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const { data } = this.state;
    const sortedData = _.orderBy(data, ['createTime'], ['desc']);
    return (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '100%' }}
        >
          <AgGridReact
            // properties
            defaultColDef={this.state.defaultColDef}
            columnDefs={this.state.columnDefs}
            rowData={sortedData}
            suppressAggFuncInHeader={true}
            animateRows={true}
            pivotPanelShow={false}
            frameworkComponents={this.state.frameworkComponents}
            groupDefaultExpanded={-1}
            getRowNodeId={data => data.id}
            rowSelection="single"
            onGridReady={this._onGridReady}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      isLoaded,
      className = 'grid-wrapper'
    } = this.state;
    return (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '88%' }}
        >
          {isLoaded ? (
            <Loader active inverted content="Loading..." />
          ) : this._createGrid()}
        </div>
        </div>
    );
  }
}
